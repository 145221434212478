import React from "react";

export default function CloudInfraImg(props) {
  const theme = props.theme;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      width="80%"
      height="80%"
      viewBox="0 0 733.86247 702.19479"

    >
      <title>cloud_files</title>
      <path
        d="M257.07474,533.1599a1.11537,1.11537,0,0,1-.23571-.46222,351.19117,351.19117,0,0,1,478.94006-405.746,1.11976,1.11976,0,1,1-.87626,2.061C612.32539,76.89059,473.23316,98.13255,371.90572,184.44937,271.19684,270.23927,227.9405,403.478,259.01686,532.17137a1.12007,1.12007,0,0,1-1.94212.98853Z"
        transform="translate(-233.06876 -98.90261)"
        fill="#e6e6e6"
      />
      <path
        d="M461.79585,773.48566a1.11976,1.11976,0,0,1,1.28506-1.75985C585.19759,822.86767,723.62042,801.34331,824.331,715.552,926.61163,628.42316,970.04606,494.49037,937.6846,366.02043a1.12,1.12,0,0,1,2.17242-.54614,346.59614,346.59614,0,0,1-5.75077,189.35434A353.6721,353.6721,0,0,1,825.78337,717.257a351.07362,351.07362,0,0,1-363.569,56.53446A1.113,1.113,0,0,1,461.79585,773.48566Z"
        transform="translate(-233.06876 -98.90261)"
        fill="#e6e6e6"
      />
      <path
        d="M435.91449,793.36365a1.11922,1.11922,0,0,1,.12628-1.57869L965.085,341.11317a1.11987,1.11987,0,1,1,1.45241,1.705L437.49318,793.48994A1.11922,1.11922,0,0,1,435.91449,793.36365Z"
        transform="translate(-233.06876 -98.90261)"
        fill="#e6e6e6"
      />
      <path
        d="M233.33628,555.55682a1.11959,1.11959,0,0,1,.12629-1.5787L762.50681,103.30633a1.11988,1.11988,0,0,1,1.45241,1.705L234.915,555.6831A1.11959,1.11959,0,0,1,233.33628,555.55682Z"
        transform="translate(-233.06876 -98.90261)"
        fill="#e6e6e6"
      />
      <path
        d="M435.91449,793.36365a1.11922,1.11922,0,0,1,.12628-1.57869L965.085,341.11317a1.11987,1.11987,0,1,1,1.45241,1.705L437.49318,793.48994A1.11922,1.11922,0,0,1,435.91449,793.36365Z"
        transform="translate(-233.06876 -98.90261)"
        fill="#e6e6e6"
      />
      <path
        d="M851.60079,284.65378H646.84415v-1.07216a3.14805,3.14805,0,0,0-3.14805-3.14805H557.14758a3.14805,3.14805,0,0,0-3.148,3.14805v1.07216H348.39885A13.85024,13.85024,0,0,0,334.54861,298.504V578.87887a13.85024,13.85024,0,0,0,13.85024,13.85024H851.60073a13.8503,13.8503,0,0,0,13.8503-13.85029V298.504A13.85024,13.85024,0,0,0,851.60079,284.65378Z"
        transform="translate(-233.06876 -98.90261)"
        fill="#3f3d56"
      />
      <path
        d="M359.41357,314.13086a1.29773,1.29773,0,0,0-1.29589,1.29639V581.36914a1.29732,1.29732,0,0,0,1.29589,1.2959H840.58594a1.297,1.297,0,0,0,1.2959-1.2959V315.42725a1.29742,1.29742,0,0,0-1.2959-1.29639Z"
        transform="translate(-233.06876 -98.90261)"
        fill={theme.accentColor}
      />
      <circle cx="366.50903" cy="197.56776" r="5.06425" fill={theme.accentColor} />
      <path
        d="M874.67264,573.83276H826.48442v-4.97648c0-.27332-.30819-.49487-.6884-.49487H809.27435c-.38021,0-.68841.22155-.68841.49487v4.97648h-10.326v-4.97648c0-.27332-.3082-.49487-.68841-.49487H781.04982c-.38021,0-.6884.22155-.6884.49487v4.97648H770.03537v-4.97648c0-.27332-.30819-.49487-.6884-.49487H752.8253c-.38021,0-.68841.22155-.68841.49487v4.97648h-10.326v-4.97648c0-.27332-.3082-.49487-.68841-.49487H724.60077c-.38021,0-.6884.22155-.6884.49487v4.97648H713.58632v-4.97648c0-.27332-.30819-.49487-.6884-.49487H696.37625c-.38021,0-.68841.22155-.68841.49487v4.97648h-10.326v-4.97648c0-.27332-.3082-.49487-.68841-.49487H668.15172c-.38021,0-.6884.22155-.6884.49487v4.97648H657.13727v-4.97648c0-.27332-.30819-.49487-.6884-.49487H527.0291c-.38021,0-.68841.22155-.68841.49487v4.97648h-10.326v-4.97648c0-.27332-.3082-.49487-.68841-.49487H498.80457c-.38021,0-.6884.22155-.6884.49487v4.97648H487.79012v-4.97648c0-.27332-.30819-.49487-.6884-.49487H470.58005c-.38021,0-.68841.22155-.68841.49487v4.97648h-10.326v-4.97648c0-.27332-.3082-.49487-.68841-.49487H442.35552c-.38021,0-.6884.22155-.6884.49487v4.97648H431.34107v-4.97648c0-.27332-.30819-.49487-.6884-.49487H414.131c-.38021,0-.68841.22155-.68841.49487v4.97648h-10.326v-4.97648c0-.27332-.3082-.49487-.68841-.49487H385.90647c-.38021,0-.6884.22155-.6884.49487v4.97648H374.892v-4.97648c0-.27332-.30819-.49487-.6884-.49487H357.68195c-.38021,0-.68841.22155-.68841.49487v4.97648H325.327c-9.12466,0-16.52167,5.31743-16.52167,11.8768V591.08c0,6.55934,7.397,11.87677,16.52167,11.87677H874.67264c9.12466,0,16.52167-5.31743,16.52167-11.87677v-5.37041C891.19431,579.15019,883.7973,573.83276,874.67264,573.83276Z"
        transform="translate(-233.06876 -98.90261)"
        fill="#3f3d56"
      />
      <path
        d="M543.97808,426.01694H401.75433a1.11987,1.11987,0,1,1,0-2.23974H543.97808a1.11987,1.11987,0,1,1,0,2.23974Z"
        transform="translate(-233.06876 -98.90261)"
        fill="#f2f2f2"
      />
      <path
        d="M543.97808,450.65413H401.75433a1.11987,1.11987,0,1,1,0-2.23974H543.97808a1.11987,1.11987,0,1,1,0,2.23974Z"
        transform="translate(-233.06876 -98.90261)"
        fill="#f2f2f2"
      />
      <path
        d="M543.97808,475.29132H401.75433a1.11988,1.11988,0,1,1,0-2.23975H543.97808a1.11988,1.11988,0,1,1,0,2.23975Z"
        transform="translate(-233.06876 -98.90261)"
        fill="#f2f2f2"
      />
      <rect
        x="373.06222"
        y="262.72169"
        width="182.53915"
        height="175.81992"
        rx="3.35962"
        fill="#fff"
      />
      <path
        d="M642.683,454.67371a45.00606,45.00606,0,0,1,88.30268-9.48886c.54524-.01956,1.09-.04147,1.64-.04147a17.66745,17.66745,0,0,1,5.51476.93823c22.387,7.35112,16.49195,40.7751-7.07106,40.7751H673.1604a30.44228,30.44228,0,0,1-30.5093-31.51923Q642.66463,455.0061,642.683,454.67371Z"
        transform="translate(-233.06876 -98.90261)"
        fill={theme.accentColor}
      />
      <polygon
        points="462.397 355.07 462.397 336.649 452.478 336.649 452.478 355.07 444.559 355.07 450.998 366.224 457.438 377.377 463.877 366.224 470.316 355.07 462.397 355.07"
        fill="#fff"
      />
    </svg>
  );
}
